import React from 'react';
import {
    Edit,
    Toolbar,
    DeleteButton,
    SimpleForm,
    DateTimeInput,
    SaveButton
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import config from "../../config";
import FrontButton from './frontbutton'

const site = localStorage.getItem('site')

const styles = {
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
};

const useStyles = makeStyles(styles);

const ProductTitle = ({ record }) => <span>Photo {record.name}</span>;

const Photo = ({ record }) => {
    const classes = useStyles();
    const imgBaseUrl = config.imageUrl[site].large ;
    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                <img src={`${imgBaseUrl}/${record.album}/${record.name}`} alt="" className={classes.img} />
            </CardContent>
        </Card>
    );
};

const PhotoToolbar = props => {
    const {record} = props;
    return(
        <Toolbar {...props} >
            <FrontButton record={record}/>
            <SaveButton/>
            <DeleteButton />
        </Toolbar>
    )
}

export const PhotoEdit = props => {
    return (
        <Edit {...props} title={<ProductTitle/>}>
            <SimpleForm toolbar={<PhotoToolbar/>}>
            <Photo/>
            <DateTimeInput source="taken_at" fullWidth/>
            </SimpleForm>
        </Edit>
    );
};

