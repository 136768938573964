import React, { useCallback} from 'react';
import {
    List,
    Datagrid,
    TextField,
    SimpleForm,
    TextInput,
    DateField,
    BooleanField,
    Edit,
    BooleanInput,
    EditButton,
    Toolbar,
    SaveButton,
    Create,
    CloneButton,
    Pagination
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import Button from '@material-ui/core/Button';
import CategoryIcon from '@material-ui/icons/PhotoLibrary';
import { slugify } from './../common/utils';
import { stringify } from 'query-string';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-final-form';
import config from "../config";


const SaveWithNoteButton = ({ handleSubmitWithRedirect, ...props }) => {
    const form = useForm();
    const handleClick = useCallback(() => {
        form.change('slug', slugify(form.getFieldState('title').value));
        handleSubmitWithRedirect('list');
    }, [form]);
    return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />
};

const PostCreateToolbar = props => (
    <Toolbar {...props} >
        <SaveWithNoteButton
            label="Save"
        />
    </Toolbar>
);

const PreviewPic = ({ record }) => {
    const site = localStorage.getItem('site')
    const imgBaseUrl = config.imageUrl[site].thumb ;
    return (
        <img style={{height: "50px"}} src={`${imgBaseUrl}/${record.id}/${record.front_photo_name}`} alt="" />
    )
}

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const PostList = (props) => {
    return(
        <List {...props} bulkActionButtons={false} perPage={50} pagination={<PostPagination />}>
            <Datagrid>
                <PreviewPic {...props} />
                <TextField source="title" />
                <TextField source="description"/>
                <DateField source="created_at" locales="en-GB" showTime/>
                <DateField source="updated_at" locales="en-GB" showTime/>
                <BooleanField source="public" />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export const PostCreate = (props) => (
    <Create {...props}>
        <SimpleForm toolbar={<PostCreateToolbar />}>
            <TextInput source="title" fullWidth/>
            <TextInput source="description" label="short description" fullWidth/>
            <RichTextInput source="body" />
            <BooleanInput source="public" label="Public" defaultValue={false}/>
        </SimpleForm>
    </Create>
);

export const PostEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="title" fullWidth/>
            <TextInput source="description" label="short description" fullWidth/>
            <RichTextInput source="body" />
            <BooleanInput source="public" label="Public" fullWidth/>
        </SimpleForm>
    </Edit>
);
