const dev = {
    apiUrl: "http://localhost:8081",
    appSuffix: "/admin",
    imageUrl: {
        "exploradores": {
            thumb: "https://exploradoresdetenerife-dev.s3.eu-central-1.amazonaws.com/photos/thumb",
            small: "https://exploradoresdetenerife-dev.s3.eu-central-1.amazonaws.com/photos/small",
            large: "https://exploradoresdetenerife-dev.s3.eu-central-1.amazonaws.com/photos/large",
        },
        "olsteins": {
            thumb: "https://olsteins-dev.s3.eu-central-1.amazonaws.com/photos/thumb",
            small: "https://olsteins-dev.s3.eu-central-1.amazonaws.com/photos/small",
            large: "https://olsteins-dev.s3.eu-central-1.amazonaws.com/photos/large",
        },
        "vylki": {
            thumb: "https://vylki-dev.s3.eu-central-1.amazonaws.com/photos/thumb",
            small: "https://vylki-dev.s3.eu-central-1.amazonaws.com/photos/small",
            large: "https://vylki-dev.s3.eu-central-1.amazonaws.com/photos/large",
        },
    }
};

const prod = {
    apiUrl: "https://api.cliffit.es",
    appSuffix: "/admin",
    imageUrl: {
        "exploradores": {
            thumb: 'https://d2xs2t0g20ks95.cloudfront.net/photos/thumb',
            small: 'https://d2xs2t0g20ks95.cloudfront.net/photos/small',
            large: 'https://d2xs2t0g20ks95.cloudfront.net/photos/large'
        },
        "olsteins": {
            thumb: "https://olsteins-com-v2.s3.eu-central-1.amazonaws.com/photos/thumb",
            small: "https://olsteins-com-v2.s3.eu-central-1.amazonaws.com/photos/small",
            large: "https://olsteins-com-v2.s3.eu-central-1.amazonaws.com/photos/large",
        },
         "vylki": {
            thumb: "https://vylki-com.s3.eu-central-1.amazonaws.com/photos/thumb",
            small: "https://vylki-com.s3.eu-central-1.amazonaws.com/photos/small",
            large: "https://vylki-com.s3.eu-central-1.amazonaws.com/photos/large",
        },
    }

};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;

export default {
    // Add common config values here
    ...config
};

