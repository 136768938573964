import React, { useCallback} from 'react';
import {
    List,
    Datagrid,
    TextField,
    SimpleForm,
    TextInput,
    DateField,
    BooleanField,
    Edit,
    BooleanInput,
    EditButton,
    Toolbar,
    SaveButton,
    Create,
    CloneButton,
    Pagination
} from 'react-admin';
import Button from '@material-ui/core/Button';
import CategoryIcon from '@material-ui/icons/PhotoLibrary';
import { slugify } from './../common/utils';
import { stringify } from 'query-string';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-final-form';
import config from "../config";

const useStyles = makeStyles({
    icon: { paddingRight: '0.5em' },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
});

const LinkToRelatedPhotos = ({ record }) => {
    const classes = useStyles();
    return record ? (
        <Button
            size="small"
            color="primary"
            component={Link}
            to={{
                pathname: '/photos',
                search: stringify({
                    page: 1,
                    perPage: 80,
                    sort: 'id',
                    order: 'DESC',
                    filter: JSON.stringify({ album: record.id }),
                }),
            }}
            className={classes.link}
        >
            <CategoryIcon className={classes.icon} />
            VIEW PHOTOS
        </Button>
    ) : null;
};


const SaveWithNoteButton = ({ handleSubmitWithRedirect, ...props }) => {
    const form = useForm();
    const handleClick = useCallback(() => {
        form.change('slug', slugify(form.getFieldState('title').value));
        handleSubmitWithRedirect('list');
    }, [form]);
    return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />
};


const UploadButton = ({ record }) => {
    return (
        <CloneButton component={Link}
             to={{
                 pathname: "/photos/create",
                 state: {record: {album: record.id}}
             }}
             label="Upload Photos"
        />
    );
};


const AlbumCreateToolbar = props => (
    <Toolbar {...props} >
        <SaveWithNoteButton
            label="Save"
        />
    </Toolbar>
);

const PreviewPic = ({ record }) => {
    const site = localStorage.getItem('site')
    const imgBaseUrl = config.imageUrl[site].thumb ;
    return (
        <img style={{height: "50px"}} src={`${imgBaseUrl}/${record.id}/${record.front_photo_name}`} alt="" />
    )
}

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const AlbumList = (props) => {
    return(
        <List {...props} bulkActionButtons={false} perPage={50} pagination={<PostPagination />}>
            <Datagrid>
                <PreviewPic {...props} />
                <TextField source="title" />
                <DateField source="created_at" locales="en-GB"/>
                <BooleanField source="public" />
                <LinkToRelatedPhotos/>
                <UploadButton {...props} />
                <EditButton />
            </Datagrid>
        </List>
    );
};


export const AlbumCreate = (props) => (
    <Create {...props}>
        <SimpleForm toolbar={<AlbumCreateToolbar />}>
            <TextInput source="title" fullWidth/>
            <TextInput source="description" label="KM, Hours, Difficulty, Circular" fullWidth/>
            <TextInput source="url" label="Wikiloc URL" fullWidth />
            <BooleanInput source="public" label="Public" defaultValue={false}/>
        </SimpleForm>
    </Create>
);

export const AlbumEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="title" fullWidth/>
            <TextInput source="description" label="KM, Hours, Difficulty, Circular" fullWidth/>
            <TextInput source="url" label="Wikiloc URL" fullWidth/>
            <BooleanInput source="public" label="Public" fullWidth/>
        </SimpleForm>
    </Edit>
);
