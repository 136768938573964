import React from 'react';
//import {Provider} from 'react-redux';
//import {store} from './jsx/redux';
import ReactDOM from 'react-dom';
import App from './jsx/app';
// import config from "./config";
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
