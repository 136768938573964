import React from "react";
import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    ImageList,
    ImageListItem
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import {
    DateField,
    TextField,
    ImageField,
    useListContext,
    EditButton,
    BulkDeleteButton,
    CheckboxField
} from 'react-admin';
import config from '../../config'
import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';

const useStyles = makeStyles(theme => ({
    root: {
        margin: '-2px',
    },
    gridList: {
        width: '100%',
        margin: 0,
    },
    tileBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
    },
    placeholder: {
        backgroundColor: theme.palette.grey[300],
        height: '100%',
    },
    price: {
        display: 'inline',
        fontSize: '1em',
    },
    link: {
        color: '#fff',
    },
}));


const getColsForWidth = width => {
    if (width === 'xs') return 2;
    if (width === 'sm') return 3;
    if (width === 'md') return 4;
    if (width === 'lg') return 5;
    return 6;
};

const times = (nbChildren, fn) =>
    Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = ({ width, nbItems = 10 }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <ImageList
                rowHeight={180}
                cols={getColsForWidth(width)}
                className={classes.gridList}
            >
                {' '}
                {times(nbItems, key => (
                    <ImageListItem key={key}>
                        <div className={classes.placeholder} />
                    </ImageListItem>
                ))}
            </ImageList>
        </div>
    );
};

const cardStyle = {
    width: "20%",
    margin: '0em',
    display: 'inline-block',
    verticalAlign: 'top'
};

const cardActionsStyle = {
    textAlign: 'right' ,
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '8px',

}

const LoadedGridList = ({ record, resource, id, onToggleItem, children, selected, selectable }) => {
    const { ids, data, basePath } = useListContext();
    return (
        <div style={{ margin: '0.5em' }}>
        {ids.map(id =>
            <Card key={id} style={cardStyle}>
                <CardContent style={{padding: '0'}}>
                    <ImageField record={data[id]} source="absolute_url_small" />
                </CardContent>
                <CardActions style={cardActionsStyle}>
                    <EditButton resource="posts" basePath={basePath} record={data[id]} />
                    <Checkbox
                        disabled={selectable}
                        checked={selected}
                        onClick={event => onToggleItem(id, event)}
                    />
                </CardActions>
                <CardActions style={cardActionsStyle}>
                    <DateField showTime record={data[id]} source="taken_at" locales="lv-LV"/>
                </CardActions>
            </Card>
        )}
        </div>
    );
};




const GridList = ({ loaded, ...props }) =>
    loaded ? <LoadedGridList {...props} /> : <LoadingGridList {...props} />;

export default withWidth()(GridList);
