import React from 'react';
import { connect } from "react-redux";
import { Admin, Resource, fetchUtils, ShowGuesser } from 'react-admin';
import {AlbumList, AlbumCreate, AlbumEdit} from './admin/albums';
import {PostList, PostCreate, PostEdit} from './admin/posts';
import {PhotoList} from './admin/photos/list';
import {PhotoCreate} from './admin/photos/upload'
import {PhotoEdit} from './admin/photos/edit'
import authProvider from './common/authProvider';
import drfProvider from './common/drfProvider';
import { createBrowserHistory as createHistory } from 'history';
import Layout from './layout.jsx'
import config from './config';
import {SiteReducer} from './redux';

const history = createHistory();

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `JWT ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const App = () => (
    <Admin
        disableTelemetry
        layout={Layout}
        authProvider={authProvider}
        customReducers={{ site: SiteReducer }}
        title="Olsteins Admin"
        dataProvider={drfProvider(`${config.apiUrl}`, httpClient)}
        history={history}
    >
        <Resource name="albums" list={AlbumList} edit={AlbumEdit} create={AlbumCreate}  />
        <Resource name="photos" list={PhotoList} edit={PhotoEdit} show={ShowGuesser} create={PhotoCreate} />
        <Resource name="posts" list={PostList} edit={PostEdit} show={ShowGuesser} create={PostCreate} />
    </Admin>
    );

export default App;
