
const initialState = "";

export const changeSite = (name, history) => {
    localStorage.setItem('site', name);
    history.push('/')
    return ({
        type: 'CHANGE_SITE',
        name: name,
    })
};

export const SiteReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'CHANGE_SITE':
        return action.name;
      default:
        return state;
    }
};  
