import React from 'react';
import { useDataProvider, useNotify, Button } from 'react-admin';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

const FrontButton = ({ record }) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const approve = () => dataProvider
        .update('albums', {
            id: record.album,
            data: {
                front_photo_id: record.id
            }
        })
        .then(response => {
            // success side effects go here
     //       redirect('/comments');
            notify('Front Photo set');
        })
        .catch(error => {
            // failure side effects go here
            notify(`Front Photo set error: ${error.message}`, 'warning');
        });

    return <Button
        label="Set As Front Image"
        onClick={approve}
        color="primary"
    ><PhotoCamera/></Button>;
};

export default FrontButton;
