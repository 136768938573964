import React from "react";
import {
    ReferenceInput,
    SelectInput,
    ImageInput,
    ImageField,
    SimpleForm,
    Create,
    Toolbar,
    SaveButton
} from 'react-admin';
import UploadForm from './uploadForm';

const redirect = (basePath, id, data) => `/photos?filter={"album"%3A${data.album}}`;

const PhotoUploadToolbar = props => (
    <Toolbar {...props} >
    </Toolbar>
)

export const PhotoCreate = props => {
    return (
        <Create {...props}>
            <SimpleForm redirect={redirect} >
                <ReferenceInput source="album"
                                reference="albums"
                                sort={{field: 'id', order: 'ASC'}}>
                    <SelectInput optionText="title" optionValue="id"/>
                </ReferenceInput>
                <UploadForm location={props.location}/>
            </SimpleForm>
        </Create>
    )
};
