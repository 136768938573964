import config from "../config";

const apiUrl = config.apiUrl

const authProvider = {
    login: ({ username, password }) => {
        const request = new Request(`${apiUrl}/api-token-auth/`, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                localStorage.setItem('token', token);
            });
    },
    logout: (params) => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    checkAuth: () => {
        const token = localStorage.getItem('token');
        if (token === undefined)
            return Promise.reject();
        const request = new Request(`${apiUrl}/api-token-refresh/`, {
            method: 'POST',
            body: JSON.stringify({ token: token}),
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                if (token != null) {
                    localStorage.setItem('token', token);
                }
                Promise.resolve();
            });   
    },
    checkError: error => {
        const status = error.status;
        if (status === 401 || status === 403) {
            // do not return Promise.reject here ot remove token as it WILL cause logout
        }
        return Promise.resolve();
    },
    getPermissions: params => Promise.resolve(),
};
export default authProvider;
