import React, { createElement } from 'react';
import { useSelector, connect } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, useRefresh } from 'react-admin';
import { withRouter } from 'react-router-dom';
import AlbumIcon from '@material-ui/icons/PhotoAlbum';
import PostIcon from '@material-ui/icons/Edit';
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import {changeSite} from './redux';

const Menu = ({ onMenuClick, logout, changeSite, history }) => {
    const site = useSelector(state => state.site);
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const siteValue = site === "" ? localStorage.getItem('site') : site;
    return (
        <div>
            <MenuItem>
                <FormControl>
                    <InputLabel id="site-selector-label">Site</InputLabel>
                    <Select 
                        labelId="site-selector-label"
                        onChange={(event) => changeSite(event.target.value, history)}
                        value={siteValue}                     
                    >
                        <MenuItem value="exploradores">Exploradores</MenuItem>
                        <MenuItem value="olsteins">Olsteins</MenuItem>
                        <MenuItem value="vylki">Vylki</MenuItem>
                    </Select>
                </FormControl>
            </MenuItem>
            <MenuItem></MenuItem>
            <MenuItemLink
                to="/albums"
                primaryText="Albums"
                leftIcon={createElement(AlbumIcon)}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {siteValue === "vylki" &&
                <MenuItemLink
                    to="/posts"
                    primaryText="Posts"
                    leftIcon={createElement(PostIcon)}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
                }
            {isXSmall && logout}
        </div>
    );
};

const mapStateToProps = state => ({
        site: state.site.name
});

const mapDispatchToProps = {
    changeSite,
};

const MenuContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Menu);

export default withRouter(MenuContainer);
