// components/simple-dropzone.component.js
import React from "react";

import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'

import config from "../../config";

const SimpleDropZone = (props) => {

    // Payload data and url to upload files
    const getUploadParams = ({ meta }) => {
        let album = 0;
        if (props.location !== undefined && props.location.state !== undefined && props.location.state.record !== undefined) {
            album = props.location.state.record.album;
        }

        const apiUrl = config.apiUrl
        const site = localStorage.getItem('site') + config.appSuffix;
        const url = `${apiUrl}/${site}/upload/photos/?album=${album}`;
        const token = localStorage.getItem('token');
        const headers ={
            'Authorization': `JWT ${token}`
        };
        return { url: url, method: 'POST', headers: headers }
    }

    // Return the current status of files being uploaded
    const handleChangeStatus = ({ meta, file }, status, allFiles) => {
        if (status == "done") {
            allFiles.forEach(f =>{
                if (f.file === file) {
                    f.remove()
                }
            })
        }
    }

    // Return array of uploaded files after submit button is clicked
    const handleSubmit = (files, allFiles) => {
      //  console.log(files.map(f => f.meta))
        allFiles.forEach(f => f.remove())
    }
    return (
        <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            onSubmit={handleSubmit}
            submitButtonDisabled={true}
            SubmitButtonComponent={null}
            accept="image/*"
        />
    );
};

export default SimpleDropZone;