import React, {Fragment} from 'react';
import {
    List,
    Pagination,
    Filter,
    ReferenceInput,
    SelectInput
} from 'react-admin';
import GridList from './gridlist'

export const PhotoFilter = props => (
    <Filter {...props}>
        <ReferenceInput
            source="album"
            reference="albums"
            sort={{ field: 'id', order: 'ASC' }}
        >
            <SelectInput optionText="title" optionValue="id"/>
        </ReferenceInput>
    </Filter>
);

export const PhotoList = props => {
    return(
        <Fragment>
            <List
                {...props}
                filters={<PhotoFilter />}
                perPage={20}
                pagination={<Pagination rowsPerPageOptions={[10, 20, 40, 80]} />}
                sort={{ field: 'id', order: 'ASC' }}
            >
                <GridList/>
            </List>
        </Fragment>
    );
};
